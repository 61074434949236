import React from 'react';
import '../style.css'
import { NavLink } from 'react-router-dom';
import 'bootstrap';

function Header() {
    return (
        <>
            <nav className="navbar sticky-top border-bottom shadow" style={{ backgroundColor: "#fff", marginTop: "0" }}>
                <div className="container-fluid">
                    <nav className="navbar">
                        <nav className="container-fluid flex-column d-flex justify-content-start align-items-start">
                            <div>
                                <NavLink to="/" className="fs-4 fw-semibold text-deco-none ms-2" style={{ color: "black" }}><span className='text-small' style={{ color: "black" }}>Green Energy Power Solutions</span></NavLink>
                            </div>
                            <div>
                                <NavLink to="/" className="text-deco-none fw-semibold ms-2" style={{ color: "black", fontSize: "12px" }}> <span className='presented-small'>Powered by GEPS ENGINEERING</span></NavLink>
                            </div>
                        </nav>
                    </nav>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <span className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                                <span className='fa fa-bars' style={{ color: "#000" }} />
                            </span>

                            <div className="offcanvas offcanvas-end" style={{ paddingInline: "20px", backgroundColor: "#fff", color: "#000", width: "80%" }} id="offcanvasNavbar">
                                <div class="offcanvas-header">
                                    <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu Bar</h5>
                                    <span type="button" data-bs-dismiss="offcanvas" aria-label="Close" className='fa fa-times' style={{ padding: "10px", color: "#000" }} />
                                </div>
                                <div className="navbar-nav" data-bs-dismiss="offcanvas">
                                    <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="/">Home</NavLink>
                                    {/* <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="services">Services</NavLink> */}
                                    <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="about">About us</NavLink>
                                    <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="contact">Contact us</NavLink>
                                    <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="career">Career</NavLink>
                                    <NavLink className="text-pad-start fw-medium text-deco-none text-col careerBtn" activeClassName="acive" to="joinus">Join Us</NavLink>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </nav></>
    );
}

export default Header;