import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Spinner, Alert, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom'; // Get job ID from URL
import EndPoint from '../../constant/end_point';

const JobDetails = () => {
    const { jobId } = useParams(); // Extract jobId from URL
    const [selectedJob, setSelectedJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({ name: '', email: '', mobileNumber: '', resume: null });

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const response = await axios.get(EndPoint.getAllJobsApi);
                if (response.status === 200 && Array.isArray(response.data)) {
                    const job = response.data.find(job => job.job_id === jobId);
                    setSelectedJob(job || null);
                }
            } catch (error) {
                setError('Error fetching job details');
            } finally {
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSubmit = new FormData();
        formDataToSubmit.append('name', formData.name);
        formDataToSubmit.append('job_id', jobId);
        formDataToSubmit.append('email', formData.email);
        formDataToSubmit.append('mobile_number', formData.mobileNumber);
        formDataToSubmit.append('resume', formData.resume);

        try {
            const response = await axios.post(EndPoint.submitApplicationApi, formDataToSubmit, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.status === 'success') {
                alert('Application submitted successfully!');
                setFormData({ name: '', email: '', mobileNumber: '', resume: null });
                document.getElementById('formResume').value = null;
            } else {
                alert(`Error: ${response.data.message}`);
            }
        } catch (error) {
            alert('Error submitting application');
        }
    };

    return (
        <Container className="my-5">
            {loading ? (
                <Row className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row>
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : selectedJob ? (
                <>
                    <Row>
                        <Col md={8} className="mx-auto">
                            <Card className="mb-4 shadow-sm">
                                <Card.Body>
                                    <Card.Title>Job Details</Card.Title>
                                    <Table striped bordered hover responsive>
                                        <tbody>
                                            <tr>
                                                <td><strong>Job Title</strong></td>
                                                <td>{selectedJob.job_title}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Job Description</strong></td>
                                                <td>{selectedJob.job_description}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Job Type</strong></td>
                                                <td>{selectedJob.job_type}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Location</strong></td>
                                                <td>{selectedJob.job_location}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Salary</strong></td>
                                                <td>{selectedJob.salary === "0.00" ? 'As your Work' : `₹ ${selectedJob.salary}`}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Experience Required</strong></td>
                                                <td>{selectedJob.experience_required === "0" ? 'Fresher' : `${selectedJob.experience_required} years`}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Education Level</strong></td>
                                                <td>{selectedJob.education_level || 'Not specified'}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Industry</strong></td>
                                                <td>{selectedJob.industry || 'Not specified'}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Application Deadline</strong></td>
                                                <td className='text-danger'>{new Date(selectedJob.application_deadline).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Company Name</strong></td>
                                                <td>{selectedJob.company_name}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Company Description</strong></td>
                                                <td>{selectedJob.company_description || 'Not specified'}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Skills Required</strong></td>
                                                <td>{selectedJob.skills_required || 'Not specified'}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Category</strong></td>
                                                <td>{selectedJob.category || 'Not specified'}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Status</strong></td>
                                                <td className={selectedJob.status === "Open" ? "text-success" : "text-danger"}><strong>{selectedJob.status}</strong></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} className="mx-auto">
                            <h3>Apply for this Job</h3>
                            <Form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm">
                                <Form.Group controlId="formName" className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Enter your name"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formMobileNumber" className="mb-3">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="tel"  // Set the input type to "tel" for phone numbers
                                        name="mobileNumber"  // Change the name to match the mobile number field
                                        value={formData.mobileNumber}  // Bind the value to the mobile number in formData
                                        onChange={handleChange}  // Use handleChange for updating the state
                                        placeholder="Enter your mobile number"
                                        required
                                        pattern="[0-9]{10}"  // Ensure it accepts a valid 10-digit number
                                    />
                                    <Form.Text className="text-muted">
                                        Please enter your 10-digit mobile number.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formResume" className="mb-3">
                                    <Form.Label>Upload Resume</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="resume"
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Button type="submit" variant="primary" className="w-100">
                                    Submit Application
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </>
            ) : (
                <p className="text-center">Job not found</p>
            )}
        </Container>
    );
};

export default JobDetails;