import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import for navigation
import EndPoint from '../../constant/end_point';

const Career = () => {
  const [jobsList, setJobsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('active');
  const navigate = useNavigate();  // Initialize useNavigate for navigation

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(EndPoint.getAllJobsApi);
      if (response.status === 200 && Array.isArray(response.data)) {
        setJobsList(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const getActiveJobs = () => {
    const today = new Date();
    return jobsList.filter(job => new Date(job.application_deadline) >= today);
  };

  const getInactiveJobs = () => {
    const today = new Date();
    return jobsList.filter(job => new Date(job.application_deadline) < today);
  };

  const renderJobCards = (jobs) => {
    return jobs.map((job, index) => (
      <Col md={6} lg={4} key={index} className="mb-4">
        <Card>
          <Card.Body>
            <Card.Title>{job.job_title}</Card.Title>
            <Card.Text>
              <strong>Company:</strong> {job.company_name} <br />
              <strong>Location:</strong> {job.job_location} <br />
              <strong>Type:</strong> {job.job_type} <br />
              <strong>Experience Required:</strong> {job.experience_required} years <br />
            </Card.Text>
            <Card.Text className='text-danger'>
              Application Deadline : {new Date(job.application_deadline).toLocaleDateString()}
            </Card.Text>
            <Button
              variant="primary"
              onClick={() => navigate(`/career/${job.job_id}`)}  // Navigate to Job Details
            >
              Apply Now
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <Container className="my-5">
      <Row className="text-center">
        <Col>
          <h1>Join Our Team</h1>
          <p className="lead">
            We're looking for dedicated individuals to join us. Explore our open positions below and apply today!
          </p>
        </Col>
      </Row>

      {loading ? (
        <Row className="d-flex justify-content-center mt-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Row>
      ) : (
        <Tabs
          id="jobs-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="active" title="Active Jobs">
            <Row className="mt-4">
              {getActiveJobs().length > 0 ? (
                renderJobCards(getActiveJobs())
              ) : (
                <Col>
                  <p>No active jobs available at the moment.</p>
                </Col>
              )}
            </Row>
          </Tab>
          <Tab eventKey="inactive" title="Inactive Jobs">
            <Row className="mt-4">
              {getInactiveJobs().length > 0 ? (
                renderJobCards(getInactiveJobs())
              ) : (
                <Col>
                  <p>No inactive jobs available.</p>
                </Col>
              )}
            </Row>
          </Tab>
        </Tabs>
      )}
    </Container>
  );
};

export default Career;