import Footer from './utility/footer/footer';
import Header from './utility/header/header';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      </>
  );
}

export default App;
