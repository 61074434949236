import React, { useState, useEffect } from 'react';
import '../utility/style.css';
import shop_new_2 from '../assets/img/geps_sec.png';
import shop_new_3 from '../assets/img/geps_third.png';
import img_2 from '../assets/img/img_2.png';
import solar_img from '../assets/img/solar_img.jpg';
import industrial from '../assets/img/industrial.png';
import inver_bat from '../assets/img/inverter_bat.avif';
import batt_main from '../assets/img/bat_pl_ma.jpeg';
import water_pv from '../assets/img/Water_Sprinkler.png';
import { Card, Col, Container, Row, Spinner, Tabs, Tab, Button } from 'react-bootstrap';
import axios from 'axios';
import EndPoint from '../constant/end_point';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {

    const [jobsList, setJobsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState('active');
    const navigate = useNavigate();

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const response = await axios.get(EndPoint.getAllJobsApi);
            if (response.status === 200 && Array.isArray(response.data)) {
                setJobsList(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, []);

    const getActiveJobs = () => {
        const today = new Date();
        return jobsList.filter(job => new Date(job.application_deadline) >= today);
    };

    const getInactiveJobs = () => {
        const today = new Date();
        return jobsList.filter(job => new Date(job.application_deadline) < today);
    };

    const renderJobCards = (jobs) => {
        return jobs.slice(0, 6).map((job, index) => (
            <Col md={6} lg={4} key={index} className="mb-4">
                <Card>
                    <Card.Body>
                        <Card.Title>{job.job_title}</Card.Title>
                        <Card.Text>
                            <strong>Company Name:</strong> {job.company_name} <br />
                            <strong>Company Description:</strong> {job.company_description} <br />
                            <strong>Location:</strong> {job.job_location} <br />
                            <strong>Experience Required:</strong> {job.experience_required} years <br />
                        </Card.Text>
                        <Card.Text className='text-danger'>
                            Application Deadline : {new Date(job.application_deadline).toLocaleDateString()}
                        </Card.Text>
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/career/${job.job_id}`)}  // Navigate to Job Details
                        >
                            Open Details
                        </Button>
                    </Card.Body>
                </Card>
            </Col>
        ));
    };


    return (
        <div>
            <div id="carouselExampleAutoplaying1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active corousel-mb">
                        <img src={shop_new_2} className="d-block img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={shop_new_3} className="d-block img-fluid" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={img_2} className="d-block img-fluid" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='sector-container'>
                <div className='sector-head' style={{ display: "flex", backgroundColor: "transparent", justifyContent: "center" }}>
                    <div style={{ flex: "1" }}>
                        <h2>Sectors</h2>
                    </div>
                    <div style={{ flex: "1", display: "flex", justifyContent: "end" }}>
                        <div className='sector-head-explore'>
                            <span>Explore More</span>
                        </div>
                    </div>
                </div>
                <div className='sector-item'>
                    <div className='card-cu' data-text="Roof Top PV Solar">
                        <img src={solar_img} alt="a" className='sector-image' />
                    </div>
                    <div className='card-cu' data-text="Fire Control System">
                        <img src={img_2} alt="a" className='sector-image' />
                    </div>
                    <div className='card-cu' data-text="Industrial work">
                        <img src={industrial} alt="a" className='sector-image' />
                    </div>
                    <div className='card-cu' data-text="Green Energy">
                        <img src={inver_bat} alt="a" className='sector-image' />
                    </div>
                    <div className='card-cu' data-text="Recharable Battery Maintenance">
                        <img src={batt_main} alt="a" className='sector-image' />
                    </div>
                    <div className='card-cu' data-text="Water Sprinkler On PV Solar">
                        <img src={water_pv} alt="a" className='sector-image' />
                    </div>
                </div>
            </div>
            <div className="area4-container job-section my-4">
                <Container>
                    <Card className='py-4'>
                        <Row className="text-center">
                            <Col>
                                <h1>Join Our Team</h1>
                                <p className="lead">
                                    We're looking for dedicated individuals to join us. Explore our open positions below and apply today!
                                </p>
                            </Col>
                        </Row>
                    </Card>
                    {loading ? (
                        <Row className="d-flex justify-content-center mt-4">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Row>
                    ) : (
                        <Tabs
                            id="jobs-tabs"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="my-4"
                        >
                            <Tab eventKey="active" title="Active Jobs">
                                <Row className="mt-4">
                                    {getActiveJobs().length > 0 ? (
                                        renderJobCards(getActiveJobs())
                                    ) : (
                                        <Col>
                                            <p>No active jobs available at the moment.</p>
                                        </Col>
                                    )}
                                </Row>
                            </Tab>
                            <Tab eventKey="inactive" title="Inactive Jobs">
                                <Row className="mt-4">
                                    {getInactiveJobs().length > 0 ? (
                                        renderJobCards(getInactiveJobs())
                                    ) : (
                                        <Col>
                                            <p>No inactive jobs available.</p>
                                        </Col>
                                    )}
                                </Row>
                            </Tab>
                        </Tabs>
                    )}
                </Container>
            </div>
            <div className='area4-container'>
                <div className="area4-section">
                    <h2>About Us</h2>
                    <div>
                        <p>"GEPS Engineering" is a leading company specializing in industrial, PV solar, and fire control system sectors. With a strong focus on innovation and expertise, GEPS Engineering provides comprehensive solutions tailored to meet the diverse needs of industrial clients.</p>
                    </div>
                    <div>
                        <button className="btn btn-secondary" type='submit'>View More</button>
                    </div>
                </div>
                <div className="area4-section">
                    <h2>Contact Us</h2>
                    <div>
                        <p>Email 1 : contact@gepseng.in</p>
                        <p>Email 2 : aakhirhusain@gepseng.in</p>
                        <p>Phone: +91 9336885254</p>
                    </div>
                    <div>
                        <button className="btn btn-secondary" type='submit'>View More</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomePage;